<template>
  <section class="section section-standalone" id="keuangan-daerah">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Kewilayahan
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <a
              class="btn btn-loadmore"
              href="#"
              v-on:click.prevent="wilayahChange()"
            >
              CARI WILAYAH LAIN
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 doc" v-if="kewilayahan">
          <iframe
            :src="src"
            scrolling="yes"
            frameborder="0"
            id="kewilayahan"
            width="100%"
            height="450px"
          ></iframe>
        </div>
      </div>
      <div
        class="row sumber-data"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                                Kontak : ${
                                  sumber_data.kontak == null
                                    ? '-'
                                    : sumber_data.kontak
                                }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Kewilayahan'
    };
  },
  data() {
    return {
      kewilayahan: false,
      name: this.$store.getters.getLokasi,
      src: `${this.base}wilayah`,
      sumberTooltip: false,
      sumber_data: {
        sumber: 'BPS (Badan Pusat Statistik)',
        updated_at: null
      }
    };
  },
  mounted() {
    var body = document.querySelector('body');
    body.style.overflowY = 'hidden';

    this.getData();
  },
  methods: {
    getData() {
      this.src = `${this.base}wilayah`;
      window.axios.post(window.base_api + 'page/kewilayahan').then(response => {
        if (response.status == 200) {
          this.sumber_data = response.data.sumber_data;
        }
      });
      setTimeout(() => {
        this.kewilayahan = true;
      }, 1000);
    },
    wilayahChange() {
      this.kewilayahan = false;
      this.src = `${this.base}wilayah`;
      setTimeout(() => {
        this.kewilayahan = true;
      }, 500);
    },
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    }
  },
  computed: {
    base() {
      return window.links['kewilayahan' + (this.isDarkMode ? '_dark' : '')];
    },
    ...mapState(['isDarkMode'])
  }
};
</script>

<style scoped>
.doc {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  iframe {
    height: 1000px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  iframe {
    height: 475px !important;
  }
}
</style>
